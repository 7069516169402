
#id654aa4cfc239522da2e3ba28 {
  #fgfg {
padding : 1% 5px 0px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
position : relative; 
min-height : 100vh; 
font-family : 'Didact Gothic', sans-serif; 
box-shadow : 20px 15px 5px 9px #c6bebe ; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
margin : 0px 0px 0px 0px; 
z-index : 5; 
text-align : center; 
border : 0px solid #c2b7b7; 
justify-content : center; 
flex-direction : row; 
align-items : center; 
align-self : auto; 
display : flex; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
min-height : 600px; 
height : auto; 
} 
.article__title {
font-size : 3px; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
font-family : Helvetica, sans-serif; 
padding : 10px 15px 10px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i99szh {
padding : 0px 3% 0px 3%; 
color : #ffffff; 
text-decoration : underline; 
font-weight : 900; 
flex-direction : row; 
display : flex; 
justify-content : space-around; 
flex-wrap : wrap; 
} 
#i9ymbj {
display : inline; 
} 
#i1d1f1 {
color : black; 
width : 250px; 
} 
.img-cliente {
width : 100px; 
height : auto; 
} 
.labeliniciosesion {
color : #ffffff; 
} 
.buttoniniciosesion {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#1982b4 0%, #1982b4 100%); 
color : #ffffff; 
border-radius : 10px 10px 10px 10px; 
width : 172.19145625px; 
min-height : auto; 
} 
.inputdark {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
font-family : 'Didact Gothic', sans-serif; 
color : #ffffff; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
width : 100%; 
} 
.formForgotPasswordDiv {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : column; 
} 
.componentInputEmailForgotPassword {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
width : auto; 
border-top-width : initial; 
border-right-width : initial; 
border-bottom-width : initial; 
border-left-width : initial; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : black; 
border-right-color : black; 
border-bottom-color : black; 
border-left-color : black; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-top-left-radius : 15px; 
border-top-right-radius : 15px; 
border-bottom-right-radius : 15px; 
border-bottom-left-radius : 15px; 
} 
.formSubmitButtonForgotPassword {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
width : 75px; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
} 
.formSubmitButtonForgotPassword:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.formSubmitButtonForgotPassword:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.formSubmitButtonForgotPassword:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ig49 {
width : 25%; 
display : flex; 
flex-direction : column; 
justify-content : space-between; 
} 
#io5ng {
width : 72%; 
color : #ffffff; 
} 
#iifg {
min-height : 100vh; 
} 
#iyxmw {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#203541 0%, #203541 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
flex-direction : column; 
align-self : auto; 
margin : 75px 0 0 0; 
} 
#i0uip {
padding : 10px; 
} 
.itemnavigationdefault {
border-radius : 30px 30px 30px 30px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
display : flex; 
justify-content : space-around; 
align-items : center; 
color : #0b2433; 
} 
#ij9fr {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 800; 
font-size : 1.2rem; 
} 
#ib3yz {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 800; 
font-size : 1.2rem; 
} 
#i51wa {
padding : 10px; 
} 
.itemnavigation {
border-radius : 30px 30px 30px 30px; 
color : #ffffff; 
} 
#irvrr {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 800; 
font-size : 1.2rem; 
} 
#ihupb {
padding : 10px; 
} 
#if76p {
padding : 10px; 
display : block; 
font-size : 3rem; 
font-weight : 800; 
} 
#ipm2s {
padding : 10px; 
} 
#i2c23 {
padding : 10px 30px 10px 30px; 
min-height : 100px; 
margin : 70px 0 0 0; 
justify-content : space-around; 
} 
#i0k7v {
padding : 10px; 
color : white; 
} 
#igr3j {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
margin : 0px 0px 10vh 0px; 
} 
#i16zi {
display : inline-block; 
justify-content : flex-end; 
} 
#iw7af {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-self : center; 
justify-content : flex-end; 
align-items : center; 
} 
#io5do {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
} 
#i2waf {
min-height : 100px; 
padding : 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#3c5e71 0%, #3c5e71 100%); 
border-radius : 0px 0px 10px 10px; 
} 
#ix48j {
padding : 10px; 
display : flex; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#203541 0%, #203541 100%); 
border-radius : 10px 10px 0px 0px; 
} 
#iexft {
padding : 10px; 
display : block; 
text-align : center; 
} 
#ir7jg {
padding : 10px; 
} 
#ik4wg {
padding : 10px; 
display : block; 
text-align : center; 
} 
#ihq9j {
padding : 10px; 
} 
#idepg {
padding : 10px; 
display : block; 
text-align : center; 
} 
#i8ugf {
padding : 10px; 
} 
.itemproducto {
width : 150px; 
text-align : center; 
align-items : center; 
min-height : auto; 
} 
.itemnavigation:hover  {
color : #0b2433; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
.itemnavigationdefault:hover  {
color : #0b2433; 
} 
#imqh5 {
display : block; 
} 
#iiwfn {
display : block; 
} 
#iia13 {
display : block; 
} 
#izsvi {
padding : 10px; 
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#itg36 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iprma {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#ip7e1 {
padding : 5px 15px 5px 15px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
color : #fff; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#1982b4 0%, #1982b4 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#imhtl {
min-height : 100px; 
padding : 10px; 
} 

  @media only screen and (max-width: 992px) {#fgfg {
min-height : 100vh; 
box-shadow : 0px 0px 0px 0px #c6bebe ; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
height : auto; 
min-height : 576px; 
}} 
@media only screen and (max-width: 992px) {#i1d1f1 {
width : 180px; 
}} 
@media only screen and (max-width: 992px) {#i9ymbj {
width : auto; 
height : auto; 
}} 

  @media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i99szh {
justify-content : space-between; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#fgfg {
min-height : 100vh; 
}} 

}
  