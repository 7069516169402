
#id64cbc52592554645256452ae {
  #i55h {
padding : 0% 10px 0px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
position : relative; 
width : 100%; 
display : flex; 
flex-direction : column; 
label-parent-flex : 0; 
flex-wrap : nowrap; 
justify-content : space-between; 
align-items : stretch; 
align-self : auto; 
} 
#i5of {
padding : 100px 10px 100px 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#030405 0%, #030405 100%); 
} 
#icv7c {
padding : 10px 3% 10px 5%; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
min-height : auto; 
width : 100%; 
flex-direction : row; 
align-self : auto; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i8lmjf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iteqmg {
color : black; 
} 
#im4a28 {
padding : 10px; 
min-height : 100px; 
width : 510px; 
} 
#iqpkke {
padding : 10px; 
min-height : 100px; 
width : 399px; 
} 
#iy8adh {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#iiauur {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#ijoril {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#ik2c1u {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#imh7h6 {
padding : 10px; 
min-height : 100px; 
width : 368px; 
} 
#iw4n9w {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
#ianz3m {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
height : 3px; 
} 
#iufalf {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ij1xap {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
} 
#ielirh {
padding : 10px; 
width : 402px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#ii3z3k {
color : black; 
align-self : center; 
margin : 0px 5px 0px 5px; 
} 
#i1414e {
color : black; 
margin : 0px 5px 0px 5px; 
} 
#it3ooa {
color : black; 
margin : 0px 5px 0px 5px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ifxad2 {
padding : 1% 3% 1% 3%; 
justify-content : space-between; 
align-items : center; 
display : flex; 
width : 100%; 
height : 100%; 
align-self : center; 
float : left; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
text-align : center; 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 50vh; 
margin : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
top : 25%; 
left : 35vh; 
z-index : 5; 
text-align : center; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
min-height : 600px; 
height : auto; 
} 
.article__title {
font-size : 3px; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
font-family : Helvetica, sans-serif; 
padding : 10px 15px 10px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ijzglu {
padding : 10px; 
min-height : 100px; 
display : inline; 
position : relative; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#isdsyu {
padding : 10px; 
display : block; 
color : #e9d2d2; 
font-weight : 600; 
position : static; 
text-align : center; 
font-size : 30px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ibmtil {
padding : 10px; 
display : block; 
color : #e9d2d2; 
text-align : justify; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i746oo {
text-align : center; 
display : inline-block; 
position : static; 
margin : 0% 0px 0px 25%; 
width : 50%; 
font-size : 140%; 
} 
#id6iic {
padding : 10px 3% 10px 3%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
} 
#ikuk3f {
width : 53%; 
min-height : 100px; 
display : inline-block; 
} 
#i9pt4f {
color : black; 
width : 55%; 
} 
#izuf7m {
padding : 10px; 
min-height : 100px; 
width : 43%; 
display : flex; 
align-items : flex-end; 
align-self : flex-end; 
} 
#i3mkrh {
width : 25%; 
display : flex; 
flex-direction : column-reverse; 
} 
#ilb64w {
padding : 10px 10px 0px 10px; 
display : block; 
float : center; 
color : #f5f0f0; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ixd91v {
padding : 10px 10px 0px 10px; 
display : block; 
float : center; 
color : #f5f0f0; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ilbw8k {
width : 25%; 
display : flex; 
flex-direction : column-reverse; 
} 
#it6im4 {
padding : 10px 10px 0px 10px; 
display : block; 
float : center; 
color : #f5f0f0; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i7q4zk {
width : 25%; 
display : flex; 
flex-direction : column-reverse; 
} 
#il1q3h {
padding : 10px 10px 0px 10px; 
display : block; 
float : center; 
color : #f5f0f0; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ibhmfa {
width : 25%; 
display : flex; 
flex-direction : column-reverse; 
} 
#icj91z {
padding : 10px; 
min-height : 100px; 
} 
#i4cneg {
text-indent : -18.0pt; 
mso-list : l0 level1 lfo1; 
} 
#i1ujid {
text-indent : -18.0pt; 
mso-list : l0 level1 lfo1; 
} 
#i1i7hs {
font-size : 12.0pt; 
mso-bidi-font-size : 11.0pt; 
line-height : 107%; 
mso-bidi-font-family : Calibri; 
mso-bidi-theme-font : minor-latin; 
} 
#ir3tek {
font-variant-numeric : normal; 
font-variant-east-asian : normal; 
font-variant-alternates : normal; 
font-kerning : auto; 
font-optical-sizing : auto; 
font-feature-settings : normal; 
font-variation-settings : normal; 
font-weight : normal; 
font-stretch : normal; 
font-size : 7pt; 
line-height : normal; 
font-family : "Times New Roman"; 
} 
#irnmo3 {
font-size : 16px; 
} 
#ijc41c {
font-weight : bolder; 
font-size : 1rem; 
} 
#ihpv0j {
text-indent : -18pt; 
font-size : 1rem; 
} 
#ieal3y {
text-indent : -18.0pt; 
mso-list : l0 level1 lfo1; 
} 
#iirik3 {
font-weight : bolder; 
font-size : 1rem; 
} 
#i3tghk {
text-indent : -18pt; 
font-size : 1rem; 
} 
#igq2ff {
text-indent : -18pt; 
font-size : 1rem; 
} 
#ixrnwe {
text-indent : -18.0pt; 
mso-list : l0 level1 lfo1; 
} 

  @media only screen and (max-width: 992px) {#i8lmjf {
justify-content : space-between; 
}} 
@media only screen and (max-width: 992px) {#i55h {
min-height : 75vh; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {#im4a28 {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iqpkke {
width : 315px; 
}} 
@media only screen and (max-width: 992px) {#ielirh {
width : 114px; 
}} 
@media only screen and (max-width: 992px) {#iufalf {
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#imh7h6 {
width : 279px; 
}} 
@media only screen and (max-width: 992px) {#icv7c {
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#izuf7m {
flex-direction : row; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#id6iic {
flex-direction : column; 
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#ikuk3f {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i9pt4f {
width : 70%; 
}} 
@media only screen and (max-width: 992px) {#iteqmg {
width : 100%; 
}} 

  @media only screen and (max-width: 480px) {#ifxad2 {
padding : 1% 1% 0px 1%; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#izuf7m {
flex-direction : column; 
justify-content : flex-start; 
align-items : center; 
align-self : auto; 
}} 
@media only screen and (max-width: 480px) {#i9pt4f {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ijzglu {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i3mkrh {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#ibhmfa {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#i7q4zk {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#ilbw8k {
width : auto; 
}} 

}
  