
#id64de29c49255464525648103 {
  #fgfg {
padding : 1% 5px 0px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
position : relative; 
display : flex; 
flex-direction : column; 
label-parent-flex : 0; 
flex-wrap : nowrap; 
justify-content : space-between; 
align-items : stretch; 
align-self : auto; 
} 
#i5of {
padding : 0px 10px 30px 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#030405 0%, #030405 100%); 
display : flex; 
flex-direction : column; 
align-items : flex-start; 
} 
#ijn4q {
padding : 3vh 10% 3vh 10%; 
min-height : 500px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk13hrg7.png'); 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#icbglp {
min-height : 100px; 
padding : 10px; 
} 
#is002f {
padding : 10px; 
} 
#ij9nul {
padding : 5px 5px 5px 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
justify-content : center; 
align-items : center; 
flex-wrap : wrap; 
} 
#inzr6s {
background-color : transparent; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 5px 5px 5px 5px; 
font-family : Helvetica, sans-serif; 
font-size : 25px; 
width : 65%; 
} 
#i62lny {
padding : 5px 5px 5px 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
justify-content : center; 
flex-wrap : wrap; 
align-items : center; 
} 
#iiic3j {
background-color : transparent; 
width : 65%; 
font-size : 28px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#im6ilb {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : center; 
} 
#ihkf52 {
background-color : transparent; 
color : #000000; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
width : 65%; 
border-radius : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#irc4ec {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#i120ad {
background-color : transparent; 
width : 65%; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#ihdlid {
font-family : 'Didact Gothic', sans-serif; 
font-size : 28px; 
font-weight : 600; 
width : 25%; 
} 
#imp4u3 {
font-family : 'Didact Gothic', sans-serif; 
font-size : 28px; 
font-weight : 600; 
width : 25%; 
} 
#ijj15h {
width : 25%; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#idzwl7 {
width : 25%; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#i47zps {
border-radius : 10px 10px 10px 10px; 
padding : 10px 15px 10px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
color : white; 
width : 243.1406px; 
margin : auto auto auto auto; 
align-self : center; 
} 
#iqdav3 {
padding : 1% 10px 1% 10px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i8lmjf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iqpkke {
padding : 10px; 
min-height : 100px; 
width : 399px; 
} 
#iy8adh {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#iiauur {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#ijoril {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#ik2c1u {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#imh7h6 {
padding : 10px; 
min-height : 100px; 
width : 368px; 
} 
#iw4n9w {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
#ianz3m {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
height : 3px; 
} 
#iufalf {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ij1xap {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
} 
#ielirh {
padding : 10px; 
width : 402px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#i1414e {
color : black; 
margin : 0px 5px 0px 5px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#sadsa12312 {
padding : 0% 0px 0px 0px; 
align-items : center; 
display : flex; 
align-self : center; 
float : left; 
color : #2472a3; 
top : 0px; 
margin : 0px 0px 0px 0px; 
flex-direction : row; 
flex-wrap : wrap; 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
margin : 0px 0px 0px 0px; 
z-index : 5; 
text-align : center; 
border : 0px solid #c2b7b7; 
justify-content : center; 
flex-direction : row; 
align-items : center; 
align-self : auto; 
display : flex; 
} 
#izyb15 {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
#ivnfjk {
padding : 10px; 
} 
#i1vy5h {
padding : 10px; 
} 
#ixlnbd {
padding : 10px; 
min-height : 100px; 
} 
#idoznl {
padding : 0px 10px 0px 10px; 
display : block; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 41px; 
font-weight : 700; 
margin : 0 0 80px 0; 
} 
.swiper.mySwiper {
min-height : 600px; 
height : auto; 
} 
#iuzzss {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#iojt1n {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#i3k1ps {
padding : 10px; 
display : block; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 600; 
font-size : 3vh; 
} 
#iidk1h {
flex-direction : column; 
justify-content : center; 
display : flex; 
margin : 0px 0 0px 0px; 
padding : 0px 40px 0px 0px; 
} 
#ikxt8n {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
display : flex; 
justify-content : center; 
} 
#i5hnm7 {
color : black; 
height : 60vh; 
} 
#im6k85 {
padding : 10px; 
} 
.article__title {
font-size : 3px; 
} 
#i2jzug {
padding : 10px; 
} 
#ijf0dn {
padding : 1% 10px 1% 10px; 
min-height : 100px; 
align-items : stretch; 
align-self : auto; 
flex-direction : row; 
display : block; 
justify-content : flex-start; 
} 
#il1nb6 {
padding : 10px; 
display : block; 
position : relative; 
} 
#ia6ouh {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
label-parent-flex : 3; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iqxc3b {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#icev5f {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
color : #ffffff; 
font-size : large; 
} 
#il1xcp {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#igkdmr {
padding : 10px; 
min-height : 100px; 
} 
#iwa5hx {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#in7cyv {
padding : 10px; 
display : block; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 35px; 
} 
#ift2d1 {
padding : 10px 10px 10px 3%; 
flex-direction : column-reverse; 
align-items : flex-start; 
position : static; 
font-family : Helvetica, sans-serif; 
width : 50%; 
} 
#i6ipsq {
min-height : 100px; 
padding : 10px; 
} 
#i3hmy1 {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
font-size : large; 
color : #ffffff; 
} 
#isq4t3 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#i30tzk {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#im6qzf {
padding : 10px; 
min-height : 100px; 
} 
#ikgsef {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#ibp7o3 {
min-height : 100px; 
padding : 10px; 
} 
#ill7mc {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i5jqld {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
font-size : large; 
color : #ffffff; 
} 
#ingim6 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#i2zh1q {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#i2unee {
padding : 10px; 
min-height : 100px; 
} 
#ik8ywd {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#ia3xxr {
min-height : 100px; 
padding : 10px; 
} 
#iagkdc {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ixzmcg {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
font-size : large; 
color : #ffffff; 
} 
#idrc8g {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#imxsgd {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#ithzbf {
padding : 10px; 
min-height : 100px; 
} 
#it0upr {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#izlyxp {
min-height : 100px; 
padding : 10px; 
} 
#i0byfs {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i6okdf {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
font-size : large; 
color : #ffffff; 
} 
#ifl058 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#irny2r {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#iynmlf {
padding : 10px; 
min-height : 100px; 
} 
#iljxoq {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#ij6n8v {
min-height : 100px; 
padding : 10px; 
} 
#ij43gl {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#ijegj6 {
padding : 10px; 
display : block; 
float : none; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
font-size : large; 
color : #ffffff; 
} 
#iihwqk {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#iub2si {
padding : 10px; 
display : block; 
text-align : justify; 
} 
#i97jxf {
padding : 10px; 
min-height : 100px; 
} 
#isv7lf {
min-height : 100px; 
width : 338.3999938964844px; 
box-shadow : 8px 23px 83px -26px black ; 
margin : auto auto auto auto; 
} 
#i108fe {
min-height : 100px; 
padding : 10px; 
} 
#iqg3k9 {
display : flex; 
justify-content : center; 
align-items : stretch; 
width : 33%; 
flex-direction : row; 
padding : 25px 0px 26px 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i0s89w {
padding : 3vh 3% 3vh 3%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(175,176,176,0.1) 0%, rgba(175,176,176,0.1) 100%); 
} 
#ix9dnu {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#ikca1r {
padding : 10px; 
min-height : 100px; 
width : 65%; 
} 
#i0v23u {
padding : 10px 10px 25px 10px; 
min-height : 100px; 
width : 35%; 
display : flex; 
justify-content : center; 
align-items : stretch; 
flex-direction : column; 
align-self : auto; 
} 
#iy6aph {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
font-size : 41px; 
text-align : center; 
} 
#izxise {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 20px; 
float : center; 
text-align : center; 
} 
#ic5gzq {
color : black; 
margin : 0 0 50px 0; 
} 
#its6nc {
padding : 25px 10px 25px 10px; 
min-height : 100px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
margin : 50px 0px 50px 0px; 
} 
#it45rn {
padding : 10px; 
display : block; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
text-align : center; 
} 
#is4twv {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
font-family : Helvetica, sans-serif; 
padding : 10px 15px 10px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ia62sr {
display : none; 
} 
#ikwh2i {
padding : 1px 10px 1px 10px; 
min-height : 100px; 
} 
#isyxuq {
padding : 10px; 
min-height : 100px; 
margin : 80px 0px 80px 0px; 
display : none; 
} 
#idl6zv {
padding : 10px; 
min-height : 100px; 
width : 285px; 
margin : 10px 0px 10px 0px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.15) ; 
} 
#ihhgjv {
padding : 10px; 
display : block; 
font-size : 25px; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
} 
#i2ejqs {
color : black; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
} 
#ii8n2h {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
} 
#il0odp {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#iw65fh {
padding : 10px; 
display : block; 
font-size : 25px; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
} 
#i56tlk {
color : black; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
} 
#ird0ml {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
} 
#i8xeyw {
padding : 10px; 
min-height : 100px; 
width : 285px; 
margin : 10px 0px 10px 0px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.15) ; 
} 
#i1gp21 {
padding : 10px; 
display : block; 
font-size : 25px; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
} 
#iqbth8 {
color : black; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
} 
#iac3g8 {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
} 
#iey6gf {
padding : 10px; 
min-height : 100px; 
width : 285px; 
margin : 10px 0px 10px 0px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.15) ; 
} 
#i44578 {
padding : 10px; 
display : block; 
font-size : 25px; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
font-weight : 700; 
} 
#ior55f {
color : black; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
} 
#iq01ck {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
} 
#i39mnb {
padding : 10px; 
min-height : 100px; 
width : 285px; 
margin : 10px 0px 10px 0px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.15) ; 
} 
#i2d52f {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
#iedcax {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : none; 
} 
#i5qnlj {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#i7y3wk {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#inp01f {
padding : 10px; 
min-height : 100px; 
} 
#ii3exs {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 600; 
text-align : center; 
font-size : 3vh; 
} 
#itb7l6 {
flex-direction : column; 
justify-content : center; 
display : flex; 
} 
#ii182q {
color : black; 
height : 45vh; 
} 
#icn7ep {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
height : 45vh; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#imbtpw {
padding : 10px; 
min-height : 100px; 
} 
#iw0bwi {
justify-content : center; 
display : flex; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
align-items : center; 
} 
#i2vtik {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#i86eug {
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 0px 25px 0px 0px; 
font-weight : 500; 
} 
#ibg3ql {
height : 100%; 
} 
#il3mwx {
padding : 10px; 
min-height : 100px; 
height : 100%; 
} 
#ingl1l {
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 0 40px 0 0; 
} 
#i9244h {
color : black; 
height : 45vh; 
} 
#if39bb {
justify-content : center; 
display : flex; 
align-items : center; 
} 
#iskzsk {
padding : 10px; 
min-height : 100px; 
} 
#idr2c4 {
display : flex; 
} 
#ivmt4l {
display : flex; 
} 
#iui9eh {
display : flex; 
} 
#ik4j6e {
display : flex; 
} 
#i3n8wx {
padding : 10px; 
display : block; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
text-align : center; 
} 
#iu10pf {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#i2myo4 {
padding : 80px 10px 80px 10px; 
min-height : 100px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
margin : 50px 0px 50px 0px; 
display : none; 
} 
#i9ipv4 {
padding : 10px; 
display : block; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
text-align : center; 
} 
#ipb61c {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#inkoxg {
padding : 10px; 
display : block; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
text-align : center; 
} 
#iuqtx7 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#iz7j17 {
padding : 10px; 
display : flex; 
justify-content : center; 
} 
#ihna7t {
padding : 10px 10px 10px 10px; 
align-items : center; 
align-self : auto; 
flex-direction : column; 
display : block; 
justify-content : space-around; 
width : 50%; 
min-height : 100px; 
} 
#i6idor {
padding : 10px 10px 0px 10px; 
display : block; 
color : #fbbb92; 
float : center; 
font-weight : 700; 
text-align : center; 
font-size : 19px; 
position : relative; 
flex-direction : column-reverse; 
margin : 0px 0px 0px 0; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iegvfq {
padding : 10px; 
display : block; 
color : #3ad0c7; 
font-weight : 700; 
font-size : 230%; 
font-family : 'Didact Gothic', sans-serif; 
text-align : left; 
} 
#iatebk {
padding : 10px 10px 10px 0; 
float : none; 
justify-content : space-around; 
display : flex; 
flex-direction : column; 
align-items : center; 
} 
#ihzv9g {
padding : 0px 10px 0px 10px; 
justify-content : flex-start; 
display : flex; 
flex-direction : row; 
} 
#in7jcb {
color : black; 
width : 41px; 
height : 40px; 
} 
#igsg5g {
color : rgba(255, 251, 251, 0.95); 
font-size : 24px; 
} 
#iz16gz {
padding : 0px 10px 0px 10px; 
display : block; 
color : rgba(255,251,251,0.95); 
font-size : 130%; 
text-align : left; 
font-family : 'Didact Gothic', sans-serif; 
text-decoration : none; 
} 
#iaws5f {
color : black; 
width : 41px; 
height : 40px; 
} 
#ilmbvb {
padding : 0px 10px 0px 10px; 
display : block; 
color : rgba(255,251,251,0.95); 
font-size : 130%; 
text-align : left; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i3kmuw {
color : rgba(255, 251, 251, 0.95); 
font-size : 24px; 
} 
#i369nf {
padding : 0px 10px 0px 10px; 
justify-content : flex-start; 
display : flex; 
flex-direction : row; 
} 
#imfvu4 {
color : black; 
width : 41px; 
height : 40px; 
} 
#i88g1b {
padding : 0px 10px 0px 10px; 
display : block; 
color : rgba(255,251,251,0.95); 
font-size : 130%; 
text-align : left; 
font-family : 'Didact Gothic', sans-serif; 
} 
#if6fse {
color : rgba(255, 251, 251, 0.95); 
font-size : 24px; 
} 
#ixroyx {
padding : 0px 10px 0px 10px; 
justify-content : flex-start; 
display : flex; 
flex-direction : row; 
} 
#iwjbok {
color : black; 
width : 41px; 
height : 40px; 
} 
#iwszhf {
padding : 0px 10px 0px 10px; 
display : block; 
color : rgba(255,251,251,0.95); 
font-size : 130%; 
text-align : left; 
float : none; 
position : static; 
left : auto; 
font-family : 'Didact Gothic', sans-serif; 
} 
#itsf8p {
color : rgba(255, 251, 251, 0.95); 
font-size : 24px; 
} 
#iy5i6h {
padding : 0px 10px 0px 10px; 
justify-content : flex-start; 
display : flex; 
flex-direction : row; 
} 
#sadsa12312:hover  {
border : 0px solid #194f70; 
} 
#i99szh {
padding : 0px 3% 0px 3%; 
color : #ffffff; 
text-decoration : underline; 
font-weight : 900; 
flex-direction : row; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#iw9lml {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#2a6d63 0%, #2a6d63 100%); 
cursor : pointer; 
color : #fff; 
__background-type : color; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
border : 0px none #262121; 
} 
#ihl6cv {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#ipu864 {
color : black; 
height : 45vh; 
} 
#if0mei {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 4vh; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iqkb1c {
padding : 10px; 
display : block; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#iirnle {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
font-size : 3vh; 
font-family : 'Didact Gothic', sans-serif; 
} 
#igtu6h {
padding : 5px 5px 5px 10px; 
text-align : left; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
width : 100%; 
} 
#i9ymbj {
display : inline; 
} 
#icisee {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 700; 
font-size : 3vh; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i1d1f1 {
color : black; 
width : 250px; 
} 
#iijpat {
padding : 10px; 
min-height : 100px; 
width : 54.99%; 
display : flex; 
align-items : center; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#ity3lq {
width : 25%; 
display : flex; 
flex-direction : column-reverse; 
} 
#i2lygn {
padding : 10px 10px 0px 10px; 
color : #ffffff; 
font-weight : 400; 
text-align : center; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
} 
#issmdu {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
font-weight : 400; 
text-align : center; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i8jfb8 {
width : 25%; 
} 
#i7ipot {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
font-weight : 400; 
text-align : center; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iqazqs {
width : 25%; 
flex-direction : column-reverse; 
} 
#irsk68 {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
font-weight : 400; 
text-align : center; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
} 
#inhf99 {
width : 25%; 
flex-direction : column-reverse; 
} 
#i2a89p {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#098930 0%, #098930 100%); 
cursor : pointer; 
color : #fff; 
position : fixed; 
z-index : 100; 
bottom : 5vh; 
right : 5vw; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
border : 0px none black; 
} 
#ickahx {
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
font-weight : 600; 
padding : 5px 0px 5px 0px; 
} 
#i593co {
padding : 10px; 
min-height : 100px; 
float : center; 
align-items : center; 
display : flex; 
} 
#ifd8zi {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#i43e3j {
color : black; 
width : 40%; 
} 
#iqz0iq {
align-items : center; 
display : flex; 
} 
#i7qddj {
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#iq5udk {
color : black; 
display : inline-block; 
width : 40px; 
} 
#i2j6zu {
display : inline; 
} 
#i9dcgp {
padding : 10px; 
min-height : 100px; 
position : relative; 
width : 100%; 
} 
#i5tgts {
font-size : 25px; 
font-weight : 500; 
text-decoration : none; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iob20e {
padding : 5px 5px; 
text-align : center; 
display : flex; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
float : left; 
width : 250px; 
height : 70px; 
opacity : 1; 
border-radius : 10px 10px 10px 10px; 
justify-content : space-around; 
align-items : center; 
align-self : center; 
border : 0 solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0d6567 0%, #0d6567 100%); 
} 
#ijmezm {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#ihocpa {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#i07jd4 {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 600; 
text-align : center; 
font-size : 3vh; 
} 
#i4a7kt {
padding : 10px; 
min-height : 100px; 
} 
#i5wclm {
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
font-weight : 600; 
padding : 5px 0px 5px 0px; 
} 
#i60p5e {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#2a6d63 0%, #2a6d63 100%); 
cursor : pointer; 
color : #fff; 
__background-type : color; 
width : 100%; 
border-radius : 10px 10px 10px 10px; 
border : 0px none #262121; 
} 
#itjyzf {
padding : 10px; 
min-height : 100px; 
float : center; 
align-items : center; 
display : flex; 
} 
#iazjlo {
flex-direction : column; 
justify-content : center; 
display : flex; 
} 
#io6oqz {
color : black; 
height : 45vh; 
} 
#ikfxa5 {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
height : 45vh; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#idzco7 {
align-items : center; 
display : flex; 
} 
#i1bzq4 {
padding : 10px; 
min-height : 100px; 
} 
#iyrem3 {
color : black; 
height : 60vh; 
} 
#iq2pxh {
padding : 10px; 
min-height : 100px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
display : flex; 
justify-content : center; 
} 
#ihytpt {
display : flex; 
justify-content : space-around; 
align-items : center; 
} 
#ixnksx {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#i66vqd {
padding : 10px; 
display : block; 
text-align : justify; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#irkxey {
padding : 10px; 
display : block; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 600; 
font-size : 3vh; 
} 
#iawao3 {
flex-direction : column; 
justify-content : center; 
display : flex; 
margin : 0px 0 0px 0px; 
padding : 0px 40px 0px 0px; 
} 
#ietzbl {
padding : 10px; 
min-height : 100px; 
} 
#i33pmj {
color : black; 
height : 45vh; 
} 
#imrwbu {
justify-content : center; 
display : flex; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
align-items : center; 
} 
#ifx4jx {
padding : 10px; 
display : block; 
font-size : 4vh; 
font-family : 'Didact Gothic', sans-serif; 
font-weight : 700; 
text-align : center; 
} 
#izi2gy {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#ifo4vp {
padding : 10px; 
display : block; 
text-align : center; 
font-weight : 700; 
font-size : 3vh; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i9khdf {
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 0px 25px 0px 0px; 
font-weight : 500; 
} 
#idcjx3 {
height : 100%; 
} 
#ic8shi {
padding : 10px; 
min-height : 100px; 
height : 100%; 
} 
#i0ywmd {
padding : 10px; 
display : block; 
font-weight : 700; 
font-size : 4vh; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
} 
#idp7be {
padding : 10px; 
display : block; 
width : 100%; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 3vh; 
} 
#i2y5vj {
padding : 10px; 
display : block; 
font-weight : 800; 
text-align : center; 
font-size : 3vh; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iza2lu {
display : flex; 
flex-direction : column; 
justify-content : center; 
padding : 0 40px 0 0; 
} 
#iuh9xq {
color : black; 
height : 45vh; 
} 
#i2bxag {
justify-content : center; 
display : flex; 
align-items : center; 
} 
#iehobh {
padding : 10px; 
min-height : 100px; 
} 
#ij64bv {
width : 100%; 
min-height : 100px; 
display : block; 
} 
#i4gupk {
padding : 10px; 
display : block; 
text-align : center; 
font-family : 'Didact Gothic', sans-serif; 
font-size : large; 
} 
#im2yrh {
width : 100%; 
display : block; 
} 
#il4rcg {
padding : 10px; 
display : block; 
text-align : center; 
color : #03cbd1; 
font-size : medium; 
} 
#i42563 {
padding : 10px; 
min-height : 100px; 
} 
#i038gn {
padding : 10px; 
display : block; 
text-align : center; 
font-size : large; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iq0c3q {
width : auto; 
min-height : auto; 
display : block; 
} 
#i46oep {
color : black; 
} 
#iowxor {
padding : 10px; 
min-height : 100px; 
position : relative; 
} 
#i97esf {
color : black; 
} 
#ilgqtp {
height : 69%; 
width : 71%; 
position : absolute; 
top : 6%; 
left : 15%; 
} 

  @media only screen and (max-width: 992px) {#i8lmjf {
justify-content : space-between; 
}} 
@media only screen and (max-width: 992px) {#fgfg {
min-height : 75vh; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {#ihdlid {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#inzr6s {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#imp4u3 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iiic3j {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ijj15h {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#idzwl7 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ihkf52 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#i120ad {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ij9nul {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#i62lny {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#im6ilb {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#irc4ec {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#iqpkke {
width : 315px; 
}} 
@media only screen and (max-width: 992px) {#ielirh {
width : 114px; 
}} 
@media only screen and (max-width: 992px) {#iufalf {
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#imh7h6 {
width : 279px; 
}} 
@media only screen and (max-width: 992px) {#idoznl {
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {#in7cyv {
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {#izyb15 {
padding : 10px 5% 10px 5%; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
height : auto; 
min-height : 576px; 
}} 
@media only screen and (max-width: 992px) {#i7qddj {
align-items : center; 
display : flex; 
}} 
@media only screen and (max-width: 992px) {#i0v23u {
display : none; 
}} 
@media only screen and (max-width: 992px) {#ikca1r {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ia62sr {
color : black; 
align-self : center; 
margin : auto auto auto auto; 
display : block; 
}} 
@media only screen and (max-width: 992px) {#ia6ouh {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iqg3k9 {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ij43gl {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#i0byfs {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#iagkdc {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ill7mc {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#idl6zv {
width : 250px; 
margin : 10px 15px 10px 15px; 
}} 
@media only screen and (max-width: 992px) {#i39mnb {
width : 250px; 
margin : 10px 15px 10px 15px; 
}} 
@media only screen and (max-width: 992px) {#il0odp {
display : flex; 
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 992px) {#iey6gf {
width : 250px; 
margin : 10px 15px 10px 15px; 
}} 
@media only screen and (max-width: 992px) {#i8xeyw {
width : 250px; 
margin : 10px 15px 10px 15px; 
}} 
@media only screen and (max-width: 992px) {#i0s89w {
padding : 120px 5% 120px 5%; 
}} 
@media only screen and (max-width: 992px) {#iijpat {
width : 73%; 
}} 
@media only screen and (max-width: 992px) {#i1d1f1 {
width : 180px; 
}} 
@media only screen and (max-width: 992px) {#i9ymbj {
width : auto; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {#iegvfq {
font-size : 28px; 
}} 
@media only screen and (max-width: 992px) {#iz16gz {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ilmbvb {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i88g1b {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#iwszhf {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i5tgts {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#iob20e {
width : 180px; 
height : 50px; 
}} 
@media only screen and (max-width: 992px) {#iuzzss {
font-size : 28px; 
}} 
@media only screen and (max-width: 992px) {#iojt1n {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i3k1ps {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#i5qnlj {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#i7y3wk {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ii3exs {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#ickahx {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ii182q {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#i2vtik {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#ihl6cv {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#icisee {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#ipu864 {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#iedcax {
padding : 10px 5% 10px 5%; 
display : none; 
}} 
@media only screen and (max-width: 992px) {#ijmezm {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#ihocpa {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i07jd4 {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#i5wclm {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#io6oqz {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#ihytpt {
align-items : center; 
display : flex; 
}} 
@media only screen and (max-width: 992px) {#ixnksx {
font-size : 28px; 
}} 
@media only screen and (max-width: 992px) {#i66vqd {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#irkxey {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#if0mei {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#iqkb1c {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#iirnle {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#i9244h {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#i33pmj {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#ifx4jx {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#izi2gy {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ifo4vp {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#i0ywmd {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#idp7be {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#i2y5vj {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#iuh9xq {
height : auto; 
}} 

  @media only screen and (max-width: 480px) {#ihdlid {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#inzr6s {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#imp4u3 {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#iiic3j {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ijj15h {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#ihkf52 {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#idzwl7 {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i120ad {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i47zps {
width : 230px; 
}} 
@media only screen and (max-width: 480px) {#idoznl {
font-size : 22px; 
}} 
@media only screen and (max-width: 480px) {#in7cyv {
font-size : 22px; 
}} 
@media only screen and (max-width: 480px) {#ill7mc {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#sadsa12312 {
padding : 39px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#ift2d1 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#izyb15 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iy6aph {
font-size : 25px; 
}} 
@media only screen and (max-width: 480px) {#izxise {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#it45rn {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#is4twv {
display : block; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ia6ouh {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iwa5hx {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#iqg3k9 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#isv7lf {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#ij43gl {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iljxoq {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#i0byfs {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#it0upr {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#iagkdc {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ik8ywd {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#ikgsef {
width : 90%; 
}} 
@media only screen and (max-width: 480px) {#idl6zv {
width : 211px; 
}} 
@media only screen and (max-width: 480px) {#i39mnb {
margin : 10px 15px 10px 15px; 
width : 211px; 
}} 
@media only screen and (max-width: 480px) {#ix9dnu {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#iey6gf {
width : 211px; 
}} 
@media only screen and (max-width: 480px) {#i44578 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ihhgjv {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i1gp21 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iw65fh {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ikca1r {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#ikwh2i {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#il0odp {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#iedcax {
padding : 10px 5% 10px 5%; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
}} 
@media only screen and (max-width: 480px) {#i8xeyw {
width : 211px; 
}} 
@media only screen and (max-width: 480px) {#izlrtu {
font-family : Helvetica, sans-serif; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#i3n8wx {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iu10pf {
display : block; 
}} 
@media only screen and (max-width: 480px) {#i9ipv4 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ipb61c {
display : block; 
}} 
@media only screen and (max-width: 480px) {#inkoxg {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iuqtx7 {
display : block; 
}} 
@media only screen and (max-width: 480px) {.formInput {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#ihna7t {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i99szh {
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#iqpkke {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#imh7h6 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iijpat {
justify-content : space-around; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ity3lq {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#inhf99 {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#iqazqs {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#i8jfb8 {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#i5hnm7 {
height : auto; 
}} 
@media only screen and (max-width: 480px) {#i7qddj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iidk1h {
width : 100%; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#itb7l6 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqz0iq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iw0bwi {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i86eug {
width : 100%; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#ingl1l {
width : 100%; 
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#if39bb {
width : 100%; 
}} 

}
  