
#id64c0853a9255464525641b77 {
  #i55h {
padding : 0px 10px 20px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
position : relative; 
width : 100%; 
display : flex; 
flex-direction : column; 
label-parent-flex : 0; 
flex-wrap : nowrap; 
justify-content : space-between; 
align-items : stretch; 
align-self : auto; 
} 
#i5of {
padding : 100px 10px 100px 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#030405 0%, #030405 100%); 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#i8lmjf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iteqmg {
color : black; 
width : 264px; 
height : 87px; 
} 
#im4a28 {
padding : 10px; 
min-height : 100px; 
width : 510px; 
} 
#iqpkke {
padding : 10px; 
min-height : 100px; 
width : 399px; 
} 
#iy8adh {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#iiauur {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#ijoril {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#ik2c1u {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#imh7h6 {
padding : 10px; 
min-height : 100px; 
width : 368px; 
} 
#iw4n9w {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
#ianz3m {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
height : 3px; 
} 
#iufalf {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ij1xap {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
} 
#ielirh {
padding : 10px; 
width : 402px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#ii3z3k {
color : black; 
align-self : center; 
margin : 0px 5px 0px 5px; 
} 
#it3ooa {
color : black; 
margin : 0px 5px 0px 5px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ifxad2 {
padding : 20px 3% 20px 3%; 
justify-content : space-between; 
align-items : flex-start; 
display : flex; 
width : 100%; 
align-self : auto; 
float : left; 
flex-direction : row; 
border : 0px none rgba(255,255,255,0.99); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2faff 0%, #f2faff 100%); 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 50vh; 
margin : 0px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
top : 25%; 
left : 35vh; 
z-index : 5; 
text-align : center; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
min-height : 600px; 
height : auto; 
} 
.article__title {
font-size : 3px; 
} 
#i3hvmk {
padding : 10px; 
width : 55%; 
display : flex; 
justify-content : center; 
align-items : flex-end; 
flex-wrap : nowrap; 
align-self : flex-end; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
font-family : Helvetica, sans-serif; 
padding : 10px 15px 10px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ijzglu {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
display : flex; 
position : relative; 
width : 33%; 
height : 95%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#194f70 0%, #194f70 100%); 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#ibmtil {
padding : 10px; 
display : block; 
color : #e9d2d2; 
border : 0 solid black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
text-align : justify; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 20px; 
} 
#igha0c {
padding : 10px 0px 10px 0; 
min-height : 100px; 
float : center; 
border : 0 solid #000000; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
display : flex; 
justify-content : center; 
width : 329.594px; 
height : 213px; 
} 
#i93zjf {
padding : 10px 0px 10px 0; 
min-height : 100px; 
float : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
display : flex; 
justify-content : center; 
width : 303.594px; 
height : 216px; 
} 
#ipds6f {
padding : 10px; 
display : block; 
color : #e9d2d2; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
font-family : 'Didact Gothic', sans-serif; 
font-size : 20px; 
} 
#i50v8w {
padding : 10px; 
min-height : 100px; 
display : flex; 
position : relative; 
width : 33%; 
height : 95%; 
border : 0px solid #2472a3; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#194f70 0%, #194f70 100%); 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#ibexwq {
padding : 10px 0px 10px 0; 
min-height : 100px; 
float : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
display : flex; 
justify-content : center; 
width : 328.594px; 
height : 216px; 
} 
#ie6ulm {
padding : 10px; 
display : block; 
color : #e9d2d2; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
text-align : justify; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 20px; 
} 
#i6qevj {
padding : 10px; 
min-height : 100px; 
display : flex; 
position : relative; 
width : 33%; 
height : 95%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#194f70 0%, #194f70 100%); 
flex-direction : column; 
justify-content : center; 
align-items : center; 
} 
#ikstg9 {
display : block; 
width : 25%; 
} 
#itwukm {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
align-self : flex-end; 
flex-direction : column; 
min-height : 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i7nvgl {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
align-self : flex-end; 
flex-direction : column; 
min-height : 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i8466w {
display : block; 
width : 25%; 
} 
#i0at8i {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
align-self : flex-end; 
flex-direction : column; 
min-height : 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#iu014u {
display : block; 
width : 25%; 
} 
#iqgcr3 {
padding : 10px 10px 0px 10px; 
display : block; 
color : #ffffff; 
text-align : center; 
align-self : flex-end; 
flex-direction : column; 
min-height : 0px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#io3hkg {
display : block; 
width : 25%; 
} 
#icdyo6 {
color : black; 
width : 202px; 
height : 191px; 
} 
#i9snf3 {
color : black; 
width : 209.594px; 
height : 204px; 
} 
#i5l6db {
color : black; 
width : 55%; 
height : 199px; 
} 
#it53zo {
padding : 10px; 
min-height : 100px; 
border : 0px none rgba(255,255,255,0.99); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#eceff7 0%, #eceff7 100%); 
font-weight : 100; 
} 
#idy4ul {
padding : 10px 10% 10px 10%; 
display : block; 
font-family : Verdana, Geneva, sans-serif; 
font-weight : 500; 
text-align : center; 
} 
#i9y65i {
font-weight : 600; 
font-size : 25px; 
font-family : 'Didact Gothic', sans-serif; 
} 
#i3n0h5 {
display : flex; 
padding : 5px 0px 5px 0px; 
justify-content : flex-start; 
flex-direction : row; 
width : 100%; 
} 
#irlz65 {
color : black; 
margin : 0px 5px 0px 5px; 
} 
#iud3za {
color : black; 
width : 10%; 
height : auto; 
} 
#itfhdn {
color : black; 
width : 10%; 
height : auto; 
} 
#ik1axz {
display : flex; 
padding : 5px 0px 5px 0px; 
justify-content : flex-start; 
flex-direction : row; 
width : 100%; 
} 
#itr4rg {
color : black; 
width : 10%; 
height : auto; 
} 
#iimp5t {
display : flex; 
padding : 5px 0px 5px 0px; 
justify-content : flex-start; 
flex-direction : row; 
width : 100%; 
} 
#iunisf {
padding : 10px; 
display : flex; 
font-weight : 300; 
color : #e9d2d2; 
flex-direction : row; 
justify-content : flex-end; 
align-items : center; 
} 
#ic6j9l {
padding : 10px; 
display : flex; 
font-weight : 300; 
color : #e9d2d2; 
flex-direction : row; 
justify-content : flex-end; 
align-items : center; 
} 
#ilpxzg {
padding : 10px; 
display : flex; 
font-weight : 300; 
color : #e9d2d2; 
flex-direction : row; 
justify-content : flex-end; 
align-items : center; 
} 
#i9y207 {
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
margin : 0px 0px 10px 0px; 
padding : 10px 0px 10px 0px; 
} 
#i9xdej {
width : 250px; 
} 
#ih2rx9 {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#ik7mdw {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#iiqv1z {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#i756yj {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#i85fx9 {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#i02zgu {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#i3cf8h {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#ijgv07 {
padding : 10px; 
display : block; 
font-family : 'Didact Gothic', sans-serif; 
font-size : 23px; 
font-weight : 600; 
color : #e9d2d2; 
text-align : center; 
} 
#i0gntp {
padding : 10px; 
min-height : 100px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#i0df2k {
width : 100%; 
min-height : 100px; 
display : block; 
} 

  @media only screen and (max-width: 992px) {#i8lmjf {
justify-content : space-between; 
}} 
@media only screen and (max-width: 992px) {#i55h {
flex-direction : row; 
display : block; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {#im4a28 {
width : 224px; 
}} 
@media only screen and (max-width: 992px) {#iqpkke {
width : 315px; 
}} 
@media only screen and (max-width: 992px) {#ielirh {
width : 114px; 
}} 
@media only screen and (max-width: 992px) {#iufalf {
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#imh7h6 {
width : 279px; 
}} 
@media only screen and (max-width: 992px) {#i3hvmk {
justify-content : space-between; 
display : none; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
height : auto; 
}} 
@media only screen and (max-width: 992px) {#ifxad2 {
flex-direction : column; 
justify-content : center; 
align-items : stretch; 
}} 
@media only screen and (max-width: 992px) {#ijzglu {
width : 98%; 
}} 
@media only screen and (max-width: 992px) {#i5of {
justify-content : center; 
display : block; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {#i9y65i {
font-size : 120%; 
}} 
@media only screen and (max-width: 992px) {#i6qevj {
width : 98%; 
}} 
@media only screen and (max-width: 992px) {#i50v8w {
width : 98%; 
}} 
@media only screen and (max-width: 992px) {#i9y207 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
}} 
@media only screen and (max-width: 992px) {#in6s8w {
display : block; 
}} 
@media only screen and (max-width: 992px) {#i9xdej {
color : black; 
width : 180px; 
}} 
@media only screen and (max-width: 992px) {.contentwrapperanimated {
display : block; 
}} 

  @media only screen and (max-width: 480px) {#ifxad2 {
padding : 39px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i3hvmk {
width : 100%; 
font-size : 100%; 
flex-wrap : wrap; 
flex-direction : column; 
justify-content : space-around; 
align-items : center; 
float : none; 
}} 
@media only screen and (max-width: 480px) {#i0at8i {
font-size : 100%; 
}} 
@media only screen and (max-width: 480px) {#itwukm {
font-size : 100%; 
}} 
@media only screen and (max-width: 480px) {#iqgcr3 {
font-size : 100%; 
}} 
@media only screen and (max-width: 480px) {#i7nvgl {
font-size : 100%; 
}} 
@media only screen and (max-width: 480px) {#ikstg9 {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#io3hkg {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#iu014u {
width : auto; 
}} 
@media only screen and (max-width: 480px) {#i8466w {
width : auto; 
}} 

}
  