
#id656772f58d2e0931f97bdecb {
  #fgfg {
padding : 1% 5px 0px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
position : relative; 
min-height : 100vh; 
font-family : 'Didact Gothic', sans-serif; 
box-shadow : 20px 15px 5px 9px #c6bebe ; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
.modalStyle {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
margin : 0px 0px 0px 0px; 
z-index : 5; 
text-align : center; 
border : 0px solid #c2b7b7; 
justify-content : center; 
flex-direction : row; 
align-items : center; 
align-self : auto; 
display : flex; 
} 
.swiper {
width : 100%; 
height : 300px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.swiper.mySwiper {
min-height : 600px; 
height : auto; 
} 
.article__title {
font-size : 3px; 
} 
.standard_button {
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 20px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
font-family : Helvetica, sans-serif; 
padding : 10px 15px 10px 15px; 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iegvfq {
padding : 10px; 
display : block; 
color : #3ad0c7; 
font-weight : 700; 
font-size : 230%; 
font-family : 'Didact Gothic', sans-serif; 
text-align : center; 
} 
#i99szh {
padding : 0px 3% 0px 3%; 
color : #ffffff; 
text-decoration : underline; 
font-weight : 900; 
flex-direction : row; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
#i9ymbj {
display : inline; 
} 
#i1d1f1 {
color : black; 
width : 250px; 
} 
.img-cliente {
width : 100px; 
height : auto; 
} 
#ix5d2 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
margin : 2rem 0 0 0; 
} 
#iw403 {
padding : 10px; 
min-height : 100px; 
width : 459.12109375px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#173c52 0%, #173c52 100%); 
} 
.labeliniciosesion {
color : #ffffff; 
} 
.buttoniniciosesion {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#1982b4 0%, #1982b4 100%); 
color : #ffffff; 
padding : 10px 10px 15px 15px; 
border-radius : 10px 10px 10px 10px; 
width : 172.19145625px; 
} 
.inputdark {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
font-family : 'Didact Gothic', sans-serif; 
color : #ffffff; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
width : 100%; 
} 
#ikf7l {
padding : 10px; 
display : flex; 
justify-content : space-around; 
} 
#i6z1y {
text-align : center; 
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
color : #fff; 
} 
#ibc69 {
padding : 10px; 
display : block; 
float : center; 
color : rgba(255,255,255,0.71); 
text-align : center; 
font-size : 1.2rem; 
margin : 2rem 0px 2rem 0px; 
} 

  @media only screen and (max-width: 992px) {#fgfg {
min-height : 100vh; 
box-shadow : 0px 0px 0px 0px #c6bebe ; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {.swiper.mySwiper {
height : auto; 
min-height : 576px; 
}} 
@media only screen and (max-width: 992px) {#i1d1f1 {
width : 180px; 
}} 
@media only screen and (max-width: 992px) {#i9ymbj {
width : auto; 
height : auto; 
}} 
@media only screen and (max-width: 992px) {#iegvfq {
font-size : 28px; 
}} 

  @media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.standard_button {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.formInput {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i99szh {
justify-content : space-between; 
height : auto; 
}} 
@media only screen and (max-width: 480px) {#iegvfq {
font-size : 25px; 
}} 
@media only screen and (max-width: 480px) {#fgfg {
min-height : 100vh; 
}} 

}
  