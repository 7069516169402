
#id64afda918b8ac2e1bb827064 {
  #i55h {
padding : 10px; 
min-height : 100vh; 
__background-type : image; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk11ptpf.png'); 
position : relative; 
} 
#ie7i {
padding : 10px 10px 10px 10px; 
min-height : 100px; 
} 
#i5of {
padding : 100px 10px 100px 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#030405 0%, #030405 100%); 
} 
#ikgn {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
font-family : Helvetica, sans-serif; 
justify-content : center; 
margin : 150px 0px 150px 0px; 
} 
#ih3j {
padding : 10px 0px 10px 0px; 
min-height : 100px; 
margin : 150px 0px 150px 0px; 
} 
#i8x7l {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
margin : 150px 0px 150px 0px; 
} 
#iyaic {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
margin : 150px 0px 150px 0px; 
} 
#ijn4q {
padding : 100px 10% 75px 10%; 
min-height : 550px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk13hrg7.png'); 
} 
#icv7c {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iigln {
padding : 10px; 
min-height : 100px; 
width : 425px; 
display : flex; 
flex-wrap : wrap; 
} 
#ih3ya {
color : black; 
} 
#ik5bn {
color : black; 
} 
#ivz5n {
padding : 10px; 
min-height : 100px; 
width : 271px; 
display : flex; 
justify-content : flex-end; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : fixed; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
.gjs-row {
display : flex; 
justify-content : flex-start; 
align-items : stretch; 
flex-wrap : wrap; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
} 
.gjs-cell {
min-height : 75px; 
width : 50%; 
} 
#idt3r {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
color : white; 
font-size : 72px; 
} 
#io1sl {
padding : 0px 10px 0px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
height : 10px; 
width : 80%; 
} 
#if42j {
padding : 75px 10px 10px 10px; 
min-height : 100px; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i6bns {
padding : 8px 8px 8px 8px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
cursor : pointer; 
color : #fff; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
font-size : 18px; 
border-radius : 8px 8px 8px 8px; 
} 
#i7t64 {
color : black; 
width : 100%; 
} 
#ieynp {
padding : 50px 10% 10px 10%; 
z-index : 3; 
} 
#iju15 {
padding : 10px; 
min-height : 100px; 
width : 370px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 20px 60px 0px rgba(0,0,0,0.05) ; 
margin : 25px 25px 25px 25px; 
} 
#i5xhw {
color : black; 
margin : auto auto auto auto; 
} 
#itjm8 {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 24px; 
text-align : center; 
font-family : Helvetica, sans-serif; 
color : #001b13; 
margin : 25px 0px 25px 0px; 
} 
#ip277 {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : Helvetica, sans-serif; 
color : #5c6965; 
text-align : center; 
} 
#irx7i {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ii5v3 {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#141623 0%, #141623 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#iw6jk {
color : black; 
margin : auto auto auto auto; 
} 
#i1enk {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#141623 0%, #141623 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#icrpu {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iei1l {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 24px; 
text-align : center; 
font-family : Helvetica, sans-serif; 
color : #001b13; 
margin : 25px 0px 25px 0px; 
} 
#i28q8 {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : Helvetica, sans-serif; 
color : #5c6965; 
text-align : center; 
} 
#i9ysw {
padding : 10px; 
min-height : 100px; 
width : 370px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 20px 60px 0px rgba(0,0,0,0.05) ; 
margin : 25px 25px 25px 25px; 
} 
#iksr9 {
color : black; 
margin : auto auto auto auto; 
} 
#if84z {
padding : 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#141623 0%, #141623 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#im7f4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#icetg {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 24px; 
text-align : center; 
font-family : Helvetica, sans-serif; 
color : #001b13; 
margin : 25px 0px 25px 0px; 
} 
#ibb26r {
padding : 10px; 
display : block; 
font-size : 20px; 
font-family : Helvetica, sans-serif; 
color : #5c6965; 
text-align : center; 
} 
#ivvrqh {
padding : 10px; 
min-height : 100px; 
width : 370px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
box-shadow : 0px 20px 60px 0px rgba(0,0,0,0.05) ; 
margin : 25px 25px 25px 25px; 
} 
#i3kv92 {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
display : flex; 
justify-content : center; 
} 
#io8thq {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 50px; 
text-align : left; 
font-family : Helvetica, sans-serif; 
color : #001b13; 
margin : 25px 0px 25px 0px; 
} 
#ixcyot {
padding : 10px; 
display : block; 
font-size : 18px; 
font-family : Helvetica, sans-serif; 
color : #5c6965; 
text-align : left; 
margin : 0px 0px 25px 0px; 
} 
#i9pm15 {
padding : 8px 8px 8px 8px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
cursor : pointer; 
color : #fff; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
font-size : 18px; 
border-radius : 8px 8px 8px 8px; 
} 
#icm6cf {
padding : 10px 10% 10px 10%; 
} 
#iwhbej {
padding : 10px; 
display : block; 
font-weight : 400; 
font-size : 50px; 
text-align : center; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
margin : 25px 0px 25px 0px; 
} 
#i6altf {
padding : 10px; 
display : block; 
font-size : 18px; 
font-family : Helvetica, sans-serif; 
color : #5c6965; 
text-align : left; 
margin : 0px 0px 25px 0px; 
} 
#i79kaf {
padding : 10px; 
min-height : 100px; 
flex-wrap : wrap; 
display : flex; 
align-self : center; 
align-items : center; 
justify-content : flex-start; 
} 
#i4rkbi {
padding : 10px 12px 10px 12px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(white 0%, white 100%); 
cursor : pointer; 
color : #03cbd1; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
font-size : 18px; 
border-radius : 10px 10px 10px 10px; 
border : 1px solid #03cbd1; 
width : 250px; 
margin : 0 50px 0 0; 
} 
#imbntg {
padding : 10px 12px 10px 12px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(white 0%, white 100%); 
cursor : pointer; 
color : #03cbd1; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
font-size : 18px; 
border-radius : 10px 10px 10px 10px; 
border : 1px solid #03cbd1; 
width : 250px; 
} 
#ilb3e4 {
padding : 10px 10% 10px 10%; 
} 
#ig18uk {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : auto; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk12jtmb.png'); 
} 
#izjrpi {
color : black; 
} 
#ib3qne {
padding : 10px; 
height : 100%; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ihylc8 {
padding : 150px 10px 150px 10px; 
min-height : 100px; 
border-radius : 22px 22px 22px 22px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#iilxvb {
padding : 10px; 
display : block; 
font-weight : 800; 
font-size : 50px; 
text-align : left; 
font-family : Helvetica, sans-serif; 
color : #001b13; 
margin : 25px 0px 25px 0px; 
} 
#ioaqgy {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : center; 
} 
.inputContainer {
width : 387px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin : 0px 10px 0px 10px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat-x : initial; 
background-repeat-y : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
__background-type : color; 
border-radius : 5px 5px 5px 5px; 
height : 54px; 
font-family : Helvetica, sans-serif; 
font-size : 26px; 
} 
#i8uyxd {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
font-size : 26px; 
margin : 0px 10px 0px 10px; 
} 
#i1ii5h {
margin : 0px 10px 0px 10px; 
} 
#i7lpq4 {
padding : 8px 8px 8px 8px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
cursor : pointer; 
color : #fff; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
font-size : 18px; 
border-radius : 8px 8px 8px 8px; 
width : 284px; 
} 
#i4mxy7 {
width : 45%; 
} 
#iav115 {
width : 55%; 
} 
#i68hp6 {
padding : 10px 0px 10px 0px; 
} 
#i84n26 {
color : black; 
width : 100%; 
} 
#ioh5ti {
padding : 10px; 
display : block; 
font-weight : 600; 
font-size : 42px; 
text-align : center; 
font-family : Helvetica, sans-serif; 
color : #222222; 
margin : 25px 0px 25px 0px; 
} 
#iowgsn {
color : black; 
} 
#ibg3yq {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#iub0y4 {
color : black; 
} 
#i94g6d {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#ial8cf {
color : black; 
} 
#iu4pxb {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#io4ip2 {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Helvetica, sans-serif; 
font-size : 41px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#icbglp {
min-height : 100px; 
padding : 10px; 
} 
#is002f {
padding : 10px; 
} 
#ij9nul {
padding : 5px 5px 5px 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
justify-content : center; 
align-items : center; 
flex-wrap : wrap; 
} 
#inzr6s {
background-color : transparent; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border-radius : 5px 5px 5px 5px; 
width : 708px; 
font-family : Helvetica, sans-serif; 
font-size : 25px; 
height : 54px; 
} 
#i62lny {
padding : 5px 5px 5px 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
justify-content : center; 
flex-wrap : wrap; 
align-items : center; 
} 
#iiic3j {
background-color : transparent; 
width : 708px; 
height : 54px; 
font-size : 28px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#im6ilb {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : center; 
} 
#ihkf52 {
background-color : transparent; 
color : #000000; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
width : 708px; 
height : 54px; 
border-radius : 5px 5px 5px 5px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
} 
#irc4ec {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#i120ad {
background-color : transparent; 
width : 708px; 
height : 54px; 
font-size : 28px; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 5px 5px 5px 5px; 
} 
#ihdlid {
width : 192px; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#imp4u3 {
width : 192px; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#ijj15h {
width : 192px; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#idzwl7 {
width : 192px; 
font-family : Helvetica, sans-serif; 
font-size : 28px; 
font-weight : 600; 
} 
#i47zps {
border-radius : 10px 10px 10px 10px; 
padding : 10px 15px 10px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#03cbd1 0%, #03cbd1 100%); 
font-family : Helvetica, sans-serif; 
font-size : 19px; 
color : white; 
width : 243.1406px; 
margin : auto auto auto auto; 
align-self : center; 
} 
#iqdav3 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#io0gfm {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#i0uegp {
color : black; 
} 
#i8lmjf {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iteqmg {
color : black; 
width : 155px; 
} 
#im4a28 {
padding : 10px; 
min-height : 100px; 
width : 510px; 
} 
#iqpkke {
padding : 10px; 
min-height : 100px; 
width : 399px; 
} 
#iy8adh {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#iiauur {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#ijoril {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
font-size : 18px; 
font-weight : 600; 
} 
#ik2c1u {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 16px; 
color : #ffffff; 
font-weight : 300; 
} 
#imh7h6 {
padding : 10px; 
min-height : 100px; 
width : 368px; 
} 
#iw4n9w {
padding : 10px 10% 10px 10%; 
min-height : 100px; 
} 
#ianz3m {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
height : 3px; 
} 
#iufalf {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#ij1xap {
padding : 10px; 
display : block; 
color : #ffffff; 
font-family : Helvetica, sans-serif; 
} 
#ielirh {
padding : 10px; 
width : 402px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#ii3z3k {
color : black; 
align-self : center; 
margin : 0px 5px 0px 5px; 
} 
#i1414e {
color : black; 
margin : 0px 5px 0px 5px; 
} 
#it3ooa {
color : black; 
margin : 0px 5px 0px 5px; 
} 
#icm2ph {
color : black; 
position : absolute; 
bottom : 0px; 
width : 253.56640625px; 
height : 312px; 
left : 0px; 
} 

  @media only screen and (max-width: 992px) {#i8lmjf {
justify-content : space-between; 
}} 
@media only screen and (max-width: 992px) {#idt3r {
font-size : 40px; 
}} 
@media only screen and (max-width: 992px) {#iigln {
width : 299px; 
}} 
@media only screen and (max-width: 992px) {#ik5bn {
width : 192px; 
height : 62px; 
}} 
@media only screen and (max-width: 992px) {#ih3ya {
width : 55px; 
height : 62px; 
}} 
@media only screen and (max-width: 992px) {#i55h {
min-height : 75vh; 
}} 
@media only screen and (max-width: 992px) {#iju15 {
margin : 25px 15px 25px 15px; 
}} 
@media only screen and (max-width: 992px) {#ivvrqh {
margin : 25px 25px 25px 25px; 
}} 
@media only screen and (max-width: 992px) {#i3kv92 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i3x0ad {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#io8thq {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#iilxvb {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#igpwko {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ig18uk {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iwhbej {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {.inputContainer {
width : 271px; 
}} 
@media only screen and (max-width: 992px) {#i7lpq4 {
width : 163px; 
}} 
@media only screen and (max-width: 992px) {#iav115 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i4mxy7 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#io4ip2 {
font-size : 26px; 
}} 
@media only screen and (max-width: 992px) {#ioh5ti {
font-size : 28px; 
}} 
@media only screen and (max-width: 992px) {#ihdlid {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#inzr6s {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#imp4u3 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#iiic3j {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ijj15h {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#idzwl7 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ihkf52 {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#i120ad {
font-size : 22px; 
}} 
@media only screen and (max-width: 992px) {#ij9nul {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#i62lny {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#im6ilb {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#irc4ec {
justify-content : flex-start; 
}} 
@media only screen and (max-width: 992px) {#im4a28 {
width : 224px; 
}} 
@media only screen and (max-width: 992px) {#iqpkke {
width : 315px; 
}} 
@media only screen and (max-width: 992px) {#ielirh {
width : 114px; 
}} 
@media only screen and (max-width: 992px) {#iufalf {
justify-content : center; 
}} 
@media only screen and (max-width: 992px) {#imh7h6 {
width : 279px; 
}} 

  @media only screen and (max-width: 480px) {#idt3r {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#ipzu4 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iz9oi {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#iwhzj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ik5bn {
width : 102px; 
height : 33px; 
}} 
@media only screen and (max-width: 480px) {#ih3ya {
width : 26px; 
height : 29px; 
}} 
@media only screen and (max-width: 480px) {#iigln {
width : 150px; 
min-height : auto; 
}} 
@media only screen and (max-width: 480px) {#ivz5n {
width : 76px; 
min-height : auto; 
}} 
@media only screen and (max-width: 480px) {#i4rkbi {
margin : 0px 50px 25px 0px; 
}} 
@media only screen and (max-width: 480px) {#i0rb6y {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#isogzr {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#i0ofd3 {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ihylc8 {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 480px) {#iilxvb {
font-size : 25px; 
}} 
@media only screen and (max-width: 480px) {#io8thq {
font-size : 25px; 
}} 
@media only screen and (max-width: 480px) {#iei1l {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#i28q8 {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#ibb26r {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#icetg {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#ip277 {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#itjm8 {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iwhbej {
font-size : 25px; 
}} 
@media only screen and (max-width: 480px) {#i7lpq4 {
margin : 25px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#ioh5ti {
font-size : 24px; 
}} 
@media only screen and (max-width: 480px) {#io4ip2 {
font-size : 22px; 
}} 
@media only screen and (max-width: 480px) {#ihdlid {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#inzr6s {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#imp4u3 {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#iiic3j {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ijj15h {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#ihkf52 {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#idzwl7 {
font-size : 18px; 
}} 
@media only screen and (max-width: 480px) {#i120ad {
font-size : 18px; 
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i47zps {
width : 230px; 
}} 
@media only screen and (max-width: 480px) {#i0uegp {
width : 213px; 
height : 75px; 
}} 

}
  