
#id668be0d0914da0d427fe93f3 {
  #iw4j {
min-height : 100px; 
font-family : Montserrat; 
} 
#ips2 {
padding : 0px 2% 10px 2%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#ihk8 {
padding : 5em 5% 5em 5%; 
min-height : 50vh; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i6xx {
padding : 3rem 5% 3em 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i3dh {
padding : 3em 5% 3em 5%; 
min-height : 100px; 
} 
#i7qj2 {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
} 
#icohi {
padding : 80px 5% 10px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
} 
#itmdc {
padding : 10px; 
min-height : 100px; 
width : 317px; 
display : flex; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
border-radius : 0px 0px 25px 25px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.31) ; 
} 
#iacot {
padding : 10px; 
min-height : 100px; 
width : 800px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
} 
#ismeq {
color : black; 
width : 256px; 
min-height : 103auto; 
} 
#i2hsg {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#ij3vk {
width : auto; 
min-height : 10px; 
} 
#ikc1x {
width : auto; 
} 
#ixxmu {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#it6xg {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#if5pi {
padding : 10px; 
width : 46.38%; 
min-height : 100px; 
} 
#ip3sl {
padding : 10px; 
display : block; 
font-size : 82px; 
font-weight : 600; 
color : #0b2433; 
} 
#i803s {
padding : 10px; 
display : block; 
font-size : 18px; 
} 
#i0tfl {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#i93l3 {
padding : 10px; 
display : block; 
font-size : 23px; 
text-align : center; 
color : #808187; 
} 
#iyo7r6 {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iyghfd {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iou1pv {
padding : 10px; 
margin : 50px 0 0 0; 
} 
#imi9j3 {
color : black; 
margin : 20px 20px 20px 0px; 
width : 150px; 
} 
#i52pnr {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#iygu8i {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#i160fk {
padding : 10px; 
display : block; 
text-align : left; 
font-weight : 600; 
} 
#io8u0g {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#id3akf {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#igdgp1 {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#ifh7p5 {
padding : 10px; 
display : block; 
text-align : left; 
font-weight : 600; 
} 
#il1rnw {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#ipuzkj {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#izq2dp {
padding : 10px; 
display : block; 
text-align : center; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iok6z3 {
display : none; 
} 
#icq3pj {
display : none; 
} 
#ipgm41 {
padding : 10px; 
min-height : 100px; 
width : 374px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : flex-end; 
} 
#idaz8h {
width : auto; 
} 
#i8ax7g {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#iol6vk {
width : auto; 
} 
#ibmm3s {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
.link-navbar {
color : #96979c; 
border-radius : 15px 15px 15px 15px; 
text-decoration : none; 
} 
.link-navbar:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
text-decoration : none; 
} 
#ir8ccl {
padding : 10px; 
display : block; 
} 
#if2hqk {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ivvvb5 {
min-height : 300px; 
width : 280px; 
__background-type : image; 
background-repeat : repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilyd0i7mu.jpg'); 
border-radius : 20px 20px 20px 20px; 
} 
#iyqmu8 {
padding : 10px; 
height : 100%; 
width : 100.66%; 
border-radius : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(11,36,51,0.33) 0%, rgba(11,36,51,0.33) 100%); 
display : flex; 
align-items : flex-end; 
color : #ffffff; 
font-weight : 600; 
} 
#im4sk2 {
padding : 10px; 
min-height : 100px; 
width : 534.2265625px; 
border-radius : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#it9es4 {
min-height : 100px; 
width : 480px; 
__background-type : image; 
background-repeat : repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilyd7kkhb.jpg'); 
border-radius : 20px 20px 20px 20px; 
} 
#ir78x3 {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
} 
#irbv49 {
padding : 10px; 
min-height : 100%; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(11,36,51,0.36) 0%, rgba(11,36,51,0.36) 100%); 
color : #ffffff; 
display : flex; 
align-items : flex-end; 
align-self : flex-end; 
justify-content : flex-start; 
flex-wrap : wrap; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#iygz58 {
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
} 
.botonprimary {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
padding : 15px 25px 15px 25px; 
border-radius : 15px 15px 15px 15px; 
text-decoration : none; 
font-weight : 600; 
font-size : 18px; 
} 
#iwhka9 {
color : black; 
border-radius : 20px 20px 20px 20px; 
width : 75%; 
} 
#ia652r {
padding : 10px; 
display : block; 
} 
#ivwm6e {
padding : 10px; 
display : block; 
color : #e3f488; 
font-weight : 700; 
font-size : 4rem; 
} 
#ioggqi {
padding : 10px; 
display : block; 
color : #ffffff; 
margin : 0 0 0 10px; 
} 
#ihtsq2 {
padding : 10px; 
display : block; 
align-items : flex-start; 
} 
#iy1p8n {
padding : 10px; 
display : block; 
} 
#iy8ixg {
padding : 10px; 
display : block; 
} 
.servicios {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(248,249,250,0.47) 0%, rgba(248,249,250,0.47) 100%); 
border-radius : 20px 20px 20px 20px; 
margin : 5px 5px 5px 5px; 
font-weight : 700; 
} 
#iy6kjn {
padding : 10px; 
min-height : 100px; 
} 
.servicios:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
} 
#isbhos {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
} 
#innv15 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
align-items : center; 
} 
#i987ev {
padding : 10px; 
min-height : 100px; 
width : 523px; 
} 
#ilumsw {
padding : 10px; 
display : block; 
font-weight : 600; 
color : #0b2433; 
} 
.titulo-seccion {
font-size : 35px; 
font-weight : 700; 
} 
#i7m4ws {
padding : 10px; 
display : block; 
} 
.seccion-info {
color : #808187; 
font-weight : 600; 
} 
#ibpnu5 {
padding : 10px; 
min-height : 100px; 
width : 519px; 
} 
#i9ttxg {
padding : 10px; 
display : block; 
} 
.descripcion-seccion {
color : #9b9ca1; 
font-size : 18px; 
} 
#i7siqb {
padding : 80px 10px 10px 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
} 
#i33brf {
padding : 10px; 
min-height : 100px; 
} 
.tarjeta-1 {
width : 340px; 
border : 1px solid #f8f9fa; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i9dbzo {
padding : 10px; 
min-height : 100px; 
} 
#iwl4dh {
padding : 10px; 
min-height : 100px; 
} 
.tarjeta-contenido-superior {
border-radius : 15px 15px 15px 15px; 
margin : -50px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
} 
#iew0z4 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#i0r9n1 {
color : black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
} 
#io0uqc {
padding : 10px; 
} 
#i9nin1 {
padding : 10px; 
display : block; 
font-size : 22px; 
font-weight : 700; 
} 
#i1h1ui {
padding : 10px; 
min-height : 100px; 
} 
#ifkloh {
padding : 10px; 
display : block; 
} 
#ikllzd {
padding : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
border-radius : 10px 10px 10px 10px; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#i83x0r {
padding : 10px; 
} 
#ir6woq {
color : black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
} 
#iwocwh {
padding : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#itekaj {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#i00og4 {
padding : 10px; 
display : block; 
font-size : 22px; 
font-weight : 700; 
} 
#ihr8qa {
padding : 10px; 
} 
#inooqa {
padding : 10px; 
min-height : 100px; 
} 
#idyhxi {
padding : 10px; 
min-height : 100px; 
} 
#i7335b {
padding : 10px; 
display : block; 
} 
#ice414 {
padding : 10px; 
min-height : 100px; 
} 
#i3ng7p {
padding : 10px; 
min-height : 100px; 
} 
#ixax6f {
padding : 10px; 
display : flex; 
} 
#imtqfh {
color : black; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
} 
#iawlst {
padding : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
border-radius : 10px 10px 10px 10px; 
} 
#ietht8 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
} 
#i0r8o4 {
padding : 10px; 
display : block; 
font-size : 22px; 
font-weight : 700; 
} 
#ilytiy {
padding : 10px; 
} 
#ir7g1t {
padding : 10px; 
min-height : 100px; 
} 
#i7jdco {
padding : 10px; 
min-height : 100px; 
} 
#inl5ao {
padding : 10px; 
display : block; 
} 
#ieai39 {
padding : 10px; 
min-height : 100px; 
} 
#itcwij {
padding : 10px; 
min-height : 100px; 
} 
#in782c {
padding : 10px; 
display : flex; 
} 
#irqujg {
padding : 120px 5% 120px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i3caqq {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
align-items : stretch; 
} 
#irc04x {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#ijmh6k {
padding : 10px; 
min-height : 100px; 
width : 30%; 
} 
#i43gtg {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i39utq {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#ibf9kj {
padding : 10px; 
min-height : 100px; 
width : 30%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
border-radius : 0px 0px 15px 15px; 
color : #ffffff; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i1mwly {
padding : 10px; 
display : block; 
color : #808187; 
} 
#i6p8b1 {
padding : 10px; 
display : block; 
} 
.subtitulo {
font-size : 28px; 
color : #0b2433; 
font-weight : 700; 
} 
.descripcion2 {
font-size : 18px; 
} 
#i1zxie {
padding : 10px; 
display : block; 
} 
.contenido-significativo {
font-size : 40px; 
font-weight : 700; 
width : 208.375px; 
} 
#ijgi5b {
padding : 10px; 
display : block; 
} 
#ip083n {
padding : 10px; 
display : block; 
color : #808187; 
} 
#ildooc {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#iz2wph {
padding : 10px; 
display : block; 
} 
#ieu90w {
padding : 10px; 
min-height : 100px; 
width : 30%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
border-radius : 0px 0px 15px 15px; 
color : #ffffff; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#ic07ai {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i1mwnf {
padding : 10px; 
display : block; 
} 
#ivi9ki {
padding : 10px; 
display : block; 
color : #808187; 
} 
#i2hmye {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#id6wph {
padding : 10px; 
display : block; 
} 
#itrilx {
padding : 10px; 
min-height : 100px; 
width : 30%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
border-radius : 0px 0px 15px 15px; 
color : #0b2433; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i57dji {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#iq3f0x {
min-height : 100px; 
} 
#iph2u6 {
padding : 10px; 
display : block; 
font-weight : 600; 
color : #0b2433; 
} 
#icukx8 {
padding : 10px; 
display : block; 
font-size : 16px; 
color : #808187; 
} 
#i6044t {
padding : 10px; 
display : block; 
} 
#iq210w {
padding : 10px; 
min-height : 100px; 
} 
#i3vh0s {
padding : 10px; 
display : block; 
} 
.seccion-info.centro {
text-align : center; 
} 
#icw6p6 {
padding : 10px; 
display : block; 
font-weight : 600; 
color : #0b2433; 
} 
.titulo-seccion.centro {
text-align : center; 
} 
#ip8s2j {
color : black; 
} 
#i7mm6p {
display : none; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
margin : 5px 5px 5px 5px; 
} 
#il4qxw {
color : black; 
} 
#izkwo7 {
display : none; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#dddfe3 0%, #dddfe3 100%); 
margin : 5px 5px 5px 5px; 
} 
#ipfg0k {
color : black; 
} 
#ij95fz {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#dddfe3 0%, #dddfe3 100%); 
margin : 5px 5px 5px 5px; 
} 
#i5mci2 {
min-height : 100px; 
padding : 20px 20px 20px 20px; 
display : flex; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.49) ; 
justify-content : space-between; 
margin : 25px 0px 25px 0px; 
width : 90%; 
align-items : stretch; 
} 
#ic1f4l {
padding : 10px; 
min-height : 250px; 
width : 562.78515625px; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
font-size : 18px; 
display : flex; 
align-items : center; 
font-weight : 700; 
} 
#iqu6j1 {
color : black; 
width : 371.015625px; 
border-radius : 15px 15px 15px 15px; 
min-height : 10auto; 
} 
#iwqp1k {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i2tyw5 {
padding : 10px; 
display : block; 
width : 558.01965625px; 
min-height : 10auto; 
} 
#ihsxj5 {
padding : 10px; 
min-height : 250px; 
width : 444.875px; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
font-size : 18px; 
display : flex; 
align-items : center; 
font-weight : 700; 
} 
#itzwm6 {
color : black; 
width : 490px; 
border-radius : 15px 15px 15px 15px; 
} 
#iffvy7 {
min-height : 100px; 
padding : 20px 20px 20px 20px; 
display : flex; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.49) ; 
justify-content : space-between; 
margin : 25px 0px 25px 0px; 
width : 90%; 
align-items : stretch; 
} 
#i0eta9 {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#isfhqx {
padding : 10px; 
min-height : 250px; 
width : 435.29296875px; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
font-size : 18px; 
display : flex; 
align-items : center; 
font-weight : 700; 
} 
#ir9ytj {
color : black; 
width : 529.85546875px; 
border-radius : 15px 15px 15px 15px; 
min-height : 10auto; 
} 
#i0pb8w {
min-height : 100px; 
padding : 20px 20px 20px 20px; 
display : flex; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.49) ; 
justify-content : space-between; 
margin : 25px 0px 25px 0px; 
width : 90%; 
align-items : stretch; 
} 
#ihtp5k {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#is9wme {
color : black; 
width : 490px; 
border-radius : 15px 15px 15px 15px; 
} 
#i36c7j {
padding : 10px; 
display : block; 
} 
#iul256 {
padding : 10px; 
min-height : 250px; 
width : 425.9296875px; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
font-size : 18px; 
display : flex; 
align-items : center; 
font-weight : 700; 
} 
#idkv6s {
min-height : 100px; 
padding : 20px 20px 20px 20px; 
display : flex; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 15px 15px 15px 15px; 
box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.49) ; 
justify-content : space-between; 
margin : 25px 0px 25px 0px; 
width : 90%; 
align-items : stretch; 
} 
#im99zj {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#isqcjp {
font-size : 16pt; 
font-family : CalistoMT; 
} 
#iqwv5w {
font-size : 16pt; 
font-family : CalisMTBol; 
} 
#iy2k1t {
font-size : 16pt; 
font-family : CalistoMT; 
} 
#i8prqg {
font-size : 16pt; 
font-family : CalisMTBol; 
} 
#iisbf4 {
font-size : 16pt; 
font-family : CalistoMT; 
} 
#ib9eki {
padding : 10px; 
display : block; 
color : #e3f488; 
font-weight : 700; 
font-size : 2rem; 
width : 182.61371875px; 
} 
#i7m5pn {
padding : 10px; 
display : block; 
color : #ffffff; 
margin : 0 0 0 10px; 
} 
#ijr9pj {
padding : 10px; 
min-height : 100px; 
display : flex; 
align-items : center; 
} 
#ilfmdu-2 {
border-color : rgba(229,231,235,var(--tw-border-opacity)); 
--tw-border-opacity : 1; 
--tw-shadow : 0 0 #0000; 
--tw-ring-inset : var(--tw-empty, ); 
--tw-ring-offset-width : 0px; 
--tw-ring-offset-color : #fff; 
--tw-ring-color : rgba(59, 130, 246, 0.5); 
--tw-ring-offset-shadow : 0 0 #0000; 
--tw-ring-shadow : 0 0 #0000; 
} 
#ite2b1 {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#igkd9u {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ies1h6 {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#ijtbqa {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ia8kni {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#imyixi {
padding : 10px; 
display : block; 
} 
#iys268 {
display : flex; 
justify-content : space-around; 
} 
#ijcwp1 {
list-style-type : disc; 
} 
#i7mwle {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#ixh1ge {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#injgbi {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#iatspf {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ic8kyi {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#ib1ul3 {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#iaxxnp {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#iz8pbu {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ij9of5 {
font-size : 15.000000pt; 
font-family : 'ArialMT'; 
color : rgb(0.000000%, 12.549020%, 37.647060%); 
} 
#i5wdvw {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#iqha5i {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#i0w0rl {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#i7mmzg {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#iwdtiz {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#itdtj1 {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#i79rvb {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ikay7d {
font-size : 14.000000pt; 
font-family : 'ArialMT'; 
} 
#ig966t {
font-size : 14.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ir5jo8 {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#ituphg {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ioa9bl {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#ip9unj {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#ic0jfz {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#i2plfw {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#ibu1ew {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#iqmz7c {
font-size : 16.000000pt; 
font-family : 'CalistoMT'; 
} 
#ii19ln {
font-size : 16.000000pt; 
font-family : 'CalisMTBol'; 
} 
#io7lhv {
width : 838.1874375px; 
} 
#in7yz3 {
padding : 10px; 
min-height : 100px; 
margin : 25px 0 0 0; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#ixj2u7 {
padding : 10px; 
min-height : 180px; 
} 
#iauerl {
padding : 10px; 
} 
#iuc0og {
padding : 5px; 
} 
#iahxb3 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ina81l {
min-height : 100px; 
} 
#imxdb2 {
font-family : CalisMTBol; 
font-size : 14pt; 
} 
.gjs-dropdown-menu-container {
position : relative; 
display : block; 
} 
.gjs-dropdown-menu-trigger {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
cursor : pointer; 
font-size : 22px; 
} 
.gjs-dropdown-menu-content {
position : absolute; 
width : 120.52%; 
min-height : 128px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 5; 
} 
#iejkse {
padding : 10px; 
} 
#icmp9k {
padding : 10px; 
} 
#ifwrr4 {
display : none; 
} 
#ionor8 {
color : #fdfdfd; 
} 
#ivbmuk {
color : #ffffff; 
} 
#ic38zj {
color : #ffffff; 
} 

  @media only screen and (max-width: 992px) {#ip3sl {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#i803s {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#it6xg {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#if5pi {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ips2 {
align-items : flex-start; 
}} 
@media only screen and (max-width: 992px) {#ilumsw {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#iph2u6 {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#icw6p6 {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {.link-navbar {
font-size : 10px; 
}} 
@media only screen and (max-width: 992px) {#ij3vk {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ip8s2j {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#ipfg0k {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#il4qxw {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#idaz8h {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikc1x {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iol6vk {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#im4sk2 {
width : 300px; 
}} 
@media only screen and (max-width: 992px) {#ioggqi {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ivwm6e {
font-size : 2rem; 
}} 
@media only screen and (max-width: 992px) {#irbv49 {
min-height : 300px; 
}} 
@media only screen and (max-width: 992px) {#it9es4 {
margin : 20px 0 0 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i987ev {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ibpnu5 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i7siqb {
flex-wrap : wrap; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-1 {
margin : 0 0 50px 0; 
}} 
@media only screen and (max-width: 992px) {#irc04x {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ijmh6k {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ic1f4l {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iqu6j1 {
width : 100%; 
margin : 20px 0 0 0; 
}} 
@media only screen and (max-width: 992px) {#itzwm6 {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ihsxj5 {
margin : 20px 0 0 0; 
width : 250%; 
}} 
@media only screen and (max-width: 992px) {#isfhqx {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ir9ytj {
margin : 20px 0 0 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#is9wme {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#iul256 {
width : 100%; 
margin : 20px 0 0 0; 
}} 
@media only screen and (max-width: 992px) {#ib9eki {
font-size : 2rem; 
}} 
@media only screen and (max-width: 992px) {#i7m5pn {
font-size : 12px; 
}} 

  @media only screen and (max-width: 480px) {#itmdc {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iacot {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iok6z3 {
color : black; 
width : 200px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#icq3pj {
padding : 10px; 
min-height : 100px; 
width : 81px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ip3sl {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#i93l3 {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#ipgm41 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ilumsw {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#iph2u6 {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#icw6p6 {
font-size : 30px; 
}} 

}
  