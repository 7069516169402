
#id64aff0e88b8ac2e1bb827744 {
  #iu8x {
padding : 10px; 
min-height : 100vh; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#000000 0%, #000000 100%); 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
align-items : center; 
} 
#in3m {
padding : 10px; 
min-height : 100px; 
width : 1389px; 
height : 764px; 
__background-type : image; 
background-repeat : no-repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : contain; 
background-image : url('https://assetsprojects.s3.amazonaws.com/405gzoilk1546iz.png'); 
display : flex; 
justify-content : center; 
align-items : center; 
} 
#ipyf {
padding : 10px; 
min-height : 100px; 
width : 860px; 
} 
#io5x {
color : black; 
} 
#i29p {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
} 
#ii0y4 {
padding : 10px; 
display : block; 
font-family : Helvetica, sans-serif; 
font-size : 55px; 
text-align : center; 
color : #ffffff; 
} 
#ib1zf {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : center; 
align-items : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#irp6h {
padding : 5px 5px; 
text-align : center; 
display : inline-block; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
border-radius : 100px 100px 100px 100px; 
width : 243.0781px; 
border : 1px solid white; 
} 
#ixafu {
font-size : 22px; 
font-family : Helvetica, sans-serif; 
color : #ffffff; 
} 

  
  
}
  