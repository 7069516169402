
#id67102744a68f26b67983bf9c {
  #iw4j {
min-height : 100px; 
font-family : Montserrat; 
} 
#ips2 {
padding : 0px 2% 10px 2%; 
min-height : 100px; 
display : flex; 
justify-content : space-between; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#ihk8 {
padding : 5em 5% 5em 5%; 
min-height : 50vh; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i6xx {
padding : 3rem 5% 3em 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#icohi {
padding : 80px 5% 10px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
} 
#itmdc {
padding : 10px; 
min-height : 100px; 
width : 317px; 
display : flex; 
flex-wrap : wrap; 
align-self : center; 
align-items : center; 
justify-content : space-around; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
border-radius : 0px 0px 25px 25px; 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.31) ; 
} 
#iacot {
padding : 10px; 
min-height : 100px; 
width : 800px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
} 
#ismeq {
color : black; 
width : 256px; 
min-height : 103auto; 
} 
#i2hsg {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#ij3vk {
width : auto; 
min-height : 10px; 
} 
#ikc1x {
width : auto; 
} 
#ixxmu {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#it6xg {
padding : 10px; 
min-height : 100px; 
width : 50%; 
} 
#if5pi {
padding : 10px; 
width : 46.38%; 
min-height : 100px; 
} 
#ip3sl {
padding : 10px; 
display : block; 
font-size : 82px; 
font-weight : 600; 
color : #0b2433; 
} 
#i803s {
padding : 10px; 
display : block; 
font-size : 18px; 
} 
#i0tfl {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iyo7r6 {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iyghfd {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
#iou1pv {
padding : 10px; 
margin : 50px 0 0 0; 
} 
#imi9j3 {
color : black; 
margin : 20px 20px 20px 0px; 
width : 150px; 
} 
#i52pnr {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#iygu8i {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#i160fk {
padding : 10px; 
display : block; 
text-align : left; 
font-weight : 600; 
} 
#io8u0g {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#id3akf {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#igdgp1 {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#ifh7p5 {
padding : 10px; 
display : block; 
text-align : left; 
font-weight : 600; 
} 
#il1rnw {
padding : 10px; 
display : block; 
font-size : 12px; 
text-align : left; 
} 
#ipuzkj {
padding : 10px; 
min-height : 100px; 
width : 301px; 
} 
#izq2dp {
padding : 10px; 
display : block; 
text-align : center; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : flex; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iok6z3 {
display : none; 
} 
#icq3pj {
display : none; 
} 
#ipgm41 {
padding : 10px; 
min-height : 100px; 
width : 374px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : flex-end; 
} 
#idaz8h {
width : auto; 
} 
#i8ax7g {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
#iol6vk {
width : auto; 
} 
#ibmm3s {
padding : 10px 10px 10px 10px; 
width : auto; 
height : auto; 
display : block; 
font-size : 22px; 
} 
.link-navbar {
color : #96979c; 
border-radius : 15px 15px 15px 15px; 
text-decoration : none; 
} 
.link-navbar:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
text-decoration : none; 
} 
#ir8ccl {
padding : 10px; 
display : block; 
} 
#ivvvb5 {
min-height : 300px; 
width : 280px; 
__background-type : image; 
background-repeat : repeat; 
background-position : center center; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/404m4lim2ccrbip.jpg'); 
border-radius : 20px 20px 20px 20px; 
} 
#iyqmu8 {
padding : 10px; 
height : 100%; 
width : 100.66%; 
border-radius : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(11,36,51,0.33) 0%, rgba(11,36,51,0.33) 100%); 
display : flex; 
align-items : flex-end; 
color : #ffffff; 
font-weight : 600; 
} 
#im4sk2 {
padding : 10px; 
min-height : 100px; 
border-radius : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#it9es4 {
min-height : 100px; 
width : 480px; 
border-radius : 20px 20px 20px 20px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#irbv49 {
padding : 10px; 
min-height : 100%; 
width : 100%; 
color : #ffffff; 
display : flex; 
align-items : flex-end; 
align-self : flex-end; 
justify-content : flex-start; 
flex-wrap : wrap; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.botonprimary {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
padding : 15px 25px 15px 25px; 
border-radius : 15px 15px 15px 15px; 
text-decoration : none; 
font-weight : 600; 
font-size : 18px; 
} 
.servicios {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(248,249,250,0.47) 0%, rgba(248,249,250,0.47) 100%); 
border-radius : 20px 20px 20px 20px; 
margin : 5px 5px 5px 5px; 
font-weight : 700; 
} 
.servicios:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
color : #0b2433; 
} 
#isbhos {
padding : 120px 5% 250px 5%; 
min-height : 100px; 
} 
.titulo-seccion {
font-size : 35px; 
font-weight : 700; 
} 
.seccion-info {
color : #808187; 
font-weight : 600; 
} 
.descripcion-seccion {
color : #9b9ca1; 
font-size : 18px; 
} 
.tarjeta-1 {
width : 340px; 
border : 1px solid #f8f9fa; 
border-radius : 15px 15px 15px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
.tarjeta-contenido-superior {
border-radius : 15px 15px 15px 15px; 
margin : -50px 0px 0px 0px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
color : #ffffff; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
#irqujg {
padding : 100px 5% 120px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f8f9fa 0%, #f8f9fa 100%); 
} 
#i3caqq {
padding : 10px 0px 10px 0; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
align-items : stretch; 
} 
#irc04x {
padding : 10px 0px 10px 0px; 
min-height : 100px; 
width : 100%; 
} 
#i43gtg {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i39utq {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#ibf9kj {
padding : 10px; 
min-height : 100px; 
width : 30%; 
border-radius : 0px 0px 15px 15px; 
color : #ffffff; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i1mwly {
padding : 10px; 
display : block; 
color : #808187; 
} 
#i6p8b1 {
padding : 10px; 
display : block; 
} 
.subtitulo {
font-size : 28px; 
color : #0b2433; 
font-weight : 700; 
} 
.descripcion2 {
font-size : 18px; 
} 
.contenido-significativo {
font-size : 40px; 
font-weight : 700; 
width : 312.375px; 
} 
#ijgi5b {
padding : 10px; 
display : block; 
} 
#ip083n {
padding : 10px; 
display : block; 
color : #808187; 
} 
#ildooc {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#ieu90w {
padding : 10px; 
min-height : 100px; 
width : 30%; 
border-radius : 0px 0px 15px 15px; 
color : #ffffff; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#ic07ai {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i1mwnf {
padding : 10px; 
display : block; 
} 
#ivi9ki {
padding : 10px; 
display : block; 
color : #808187; 
} 
#i2hmye {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#itrilx {
padding : 10px; 
min-height : 100px; 
width : 30%; 
border-radius : 0px 0px 15px 15px; 
color : #0b2433; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#i57dji {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#iq3f0x {
min-height : 100px; 
} 
.seccion-info.centro {
text-align : center; 
} 
.titulo-seccion.centro {
text-align : center; 
} 
#ip8s2j {
color : black; 
} 
#i7mm6p {
display : none; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#e3f488 0%, #e3f488 100%); 
margin : 5px 5px 5px 5px; 
} 
#il4qxw {
color : black; 
} 
#izkwo7 {
display : none; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#dddfe3 0%, #dddfe3 100%); 
margin : 5px 5px 5px 5px; 
} 
#ipfg0k {
color : black; 
} 
#ij95fz {
display : block; 
padding : 10px 10px 10px 10px; 
border-radius : 50% 50% 50% 50%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#dddfe3 0%, #dddfe3 100%); 
margin : 5px 5px 5px 5px; 
} 
#iwqp1k {
display : flex; 
align-items : stretch; 
justify-content : flex-start; 
margin : -250px 0px 0px 0px; 
border-radius : 25px 25px 25px 25px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#0b2433 0%, #0b2433 100%); 
} 
#iys268 {
display : flex; 
justify-content : space-around; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.gjs-dropdown-menu-container {
position : relative; 
display : block; 
} 
.gjs-dropdown-menu-trigger {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
cursor : pointer; 
font-size : 22px; 
} 
.gjs-dropdown-menu-content {
position : absolute; 
width : 120.52%; 
min-height : 128px; 
box-shadow : rgba(0, 0, 0, 0.2) 0px 8px 16px 0px; 
z-index : 5; 
} 
#iejkse {
padding : 10px; 
} 
#icmp9k {
padding : 10px; 
} 
#ifwrr4 {
display : block; 
} 
#ionor8 {
color : #fdfdfd; 
} 
#ivbmuk {
color : #ffffff; 
} 
#ic38zj {
color : #ffffff; 
} 
#i22j24 {
height : 350px; 
width : 508px; 
border-radius : 20px 20px 20px 20px; 
} 
#i0y2lo {
height : 350px; 
width : 615px; 
} 
#ibin0r {
padding : 10px; 
} 
#i1ezla {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
} 
#i2nnhu {
color : black; 
width : 214.07799999999997px; 
min-height : 169auto; 
} 
#iyleqi {
color : black; 
border-radius : 0px 0px 15px 15px; 
} 
#ibg6qj {
color : black; 
border-radius : 0px 0px 15px 15px; 
width : 354px; 
min-height : 10auto; 
} 
#irm6ox {
color : black; 
border-radius : 0px 0px 15px 15px; 
} 
#iyr2ub {
padding : 10px; 
min-height : 100px; 
width : 30%; 
border-radius : 0px 0px 15px 15px; 
color : #0b2433; 
justify-content : center; 
align-items : center; 
display : flex; 
} 
#iy8kez {
padding : 10px; 
display : block; 
} 
#i20htm {
padding : 10px; 
display : block; 
color : #808187; 
} 
#ih3fni {
padding : 10px; 
min-height : 100px; 
width : 70%; 
} 
#ipheoy {
padding : 10px; 
min-height : 100px; 
display : flex; 
} 
#i06tr1 {
min-height : 100px; 
width : 30%; 
border-radius : 25px 0px 0px 25px; 
} 
#iyw1nu {
padding : 15px 20px 15px 20px; 
min-height : 31px; 
width : 70%; 
color : #ffffff; 
} 
#i2girj {
padding : 10px; 
min-height : 100px; 
} 
#i2ngo3 {
padding : 10px; 
min-height : 100px; 
} 
#iwuzzj {
padding : 10px; 
display : block; 
font-size : 45px; 
font-weight : 600; 
} 
#i33d6b {
padding : 10px; 
display : block; 
text-align : center; 
} 
#i4mcz4 {
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : center; 
} 
.inputContainer {
width : 439px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#i4s1ml {
padding : 10px 10px 10px 10px; 
width : 439px; 
} 
#i8oek4 {
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
width : 100%; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#113951 0%, #113951 100%); 
color : #ffffff; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#i8oek4:active  {
border : 1px solid #e3f488; 
} 
#i8oek4:hover  {
border : 1px solid #e3f488; 
} 
#iv7kbi {
color : black; 
border-radius : 25px 0px 0px 25px; 
} 
#i1l31g {
padding : 10px; 
display : block; 
color : #e3f488; 
font-weight : 600; 
font-size : 14px; 
} 

  @media only screen and (max-width: 992px) {#ip3sl {
font-size : 60px; 
}} 
@media only screen and (max-width: 992px) {#i803s {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {#it6xg {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#if5pi {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#ips2 {
align-items : flex-start; 
}} 
@media only screen and (max-width: 992px) {.link-navbar {
font-size : 10px; 
}} 
@media only screen and (max-width: 992px) {#ij3vk {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ip8s2j {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#ipfg0k {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#il4qxw {
width : 20px; 
}} 
@media only screen and (max-width: 992px) {#idaz8h {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#ikc1x {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#iol6vk {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {#im4sk2 {
width : 100%; 
min-height : 200px; 
display : flex; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#irbv49 {
min-height : 300px; 
}} 
@media only screen and (max-width: 992px) {#it9es4 {
margin : 20px 0 0 0; 
width : 100%; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-1 {
margin : 0 0 50px 0; 
}} 
@media only screen and (max-width: 992px) {#irc04x {
width : 100%; 
}} 
@media only screen and (max-width: 992px) {#i22j24 {
width : 615px; 
height : 350px; 
}} 
@media only screen and (max-width: 992px) {#i0tfl {
justify-content : space-around; 
}} 
@media only screen and (max-width: 992px) {#ivvvb5 {
display : none; 
}} 
@media only screen and (max-width: 992px) {#ipgm41 {
width : 146.656px; 
}} 
@media only screen and (max-width: 992px) {#ifwrr4 {
display : none; 
}} 
@media only screen and (max-width: 992px) {#iv7kbi {
border-radius : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {#i06tr1 {
display : flex; 
justify-content : space-around; 
align-items : center; 
}} 

  @media only screen and (max-width: 480px) {#itmdc {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iacot {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iok6z3 {
color : black; 
width : 200px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#icq3pj {
padding : 10px; 
min-height : 100px; 
width : 81px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {#ip3sl {
font-size : 35px; 
}} 
@media only screen and (max-width: 480px) {#ipgm41 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iyw1nu {
width : 100%; 
padding : 15px 5px 15px 5px; 
}} 
@media only screen and (max-width: 480px) {#i803s {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {#im4sk2 {
min-height : 10px; 
}} 
@media only screen and (max-width: 480px) {#i22j24 {
min-height : 10auto; 
}} 
@media only screen and (max-width: 480px) {#i0tfl {
align-items : center; 
}} 
@media only screen and (max-width: 480px) {#i43gtg {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#i39utq {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ibf9kj {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#itrilx {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i57dji {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#i2hmye {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ic07ai {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#ildooc {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ieu90w {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ipheoy {
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {#iyr2ub {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ih3fni {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i06tr1 {
width : 100%; 
display : block; 
min-height : 100px; 
}} 
@media only screen and (max-width: 480px) {#iwqp1k {
flex-wrap : wrap; 
min-height : 983px; 
}} 
@media only screen and (max-width: 480px) {#i2ngo3 {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#i4s1ml {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {#iv7kbi {
border-radius : 25px 25px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#i1l31g {
font-size : 10px; 
}} 
@media only screen and (max-width: 480px) {#iwuzzj {
font-size : 28px; 
}} 

}
  